import { getRgbaColor } from '@/helpers/color.helper';

export const COLOR_RED = '#ff395a';
export const COLOR_GREEN = '#aaf1c4';
export const COLOR_BLUE = '#6ec0ff';
export const COLOR_PURPLE = '#d5cbf5';
export const COLOR_YELLOW = '#f4ff73';
export const COLOR_LIGHT = '#f1f5f7';
export const COLOR_DARK = '#6e7076';
export const COLOR_GREY = '#e7edee';
export const COLOR_GREY_20 = getRgbaColor(COLOR_GREY, '0.8');
export const COLOR_WHITE = '#fff';

export const COLORS = [COLOR_GREEN, COLOR_YELLOW, COLOR_RED, COLOR_BLUE, COLOR_PURPLE];

export const COLOR_GREEN_0_BASE = '#daf2ef';
export const COLOR_GREEN_0 = getRgbaColor(COLOR_GREEN_0_BASE, '0.9');
export const COLOR_GREEN_5_BASE = '#B5E6DF';
export const COLOR_GREEN_5 = getRgbaColor(COLOR_GREEN_5_BASE, '0.9');
export const COLOR_GREEN_10_BASE = '#90D9CF';
export const COLOR_GREEN_10 = getRgbaColor(COLOR_GREEN_10_BASE, '0.9');
export const COLOR_GREEN_20 = getRgbaColor('#6ACDBF', '0.8');
export const COLOR_GREEN_30 = getRgbaColor('#46C1AF', '0.8');

export const COLOR_YELLOW_0 = '#FCF9E8';
export const COLOR_YELLOW_5 = '#F5E6AB';
export const COLOR_YELLOW_10 = getRgbaColor('#F2D675', '0.95');
export const COLOR_YELLOW_20 = getRgbaColor('#F0C33C', '0.95');
export const COLOR_YELLOW_30 = getRgbaColor('#DBA617', '0.85');

export const COLOR_ORANGE_0 = getRgbaColor('#FBE2D6', '0.9');
export const COLOR_ORANGE_5_BASE = '#F7C5AD';
export const COLOR_ORANGE_5 = getRgbaColor(COLOR_ORANGE_5_BASE, '0.9');
export const COLOR_ORANGE_10_BASE = '#F3A884';
export const COLOR_ORANGE_10 = getRgbaColor(COLOR_ORANGE_10_BASE, '0.9');
export const COLOR_ORANGE_20 = getRgbaColor('#EF8B5B', '0.8');
export const COLOR_ORANGE_30 = getRgbaColor('#EB6F33', '0.8');

export const COLOR_RED_0 = getRgbaColor('#FBDCE1', '0.9');
export const COLOR_RED_5 = getRgbaColor('#F7BAC4', '0.9');
export const COLOR_RED_10 = getRgbaColor('#F398A6', '0.9');
export const COLOR_RED_20_BASE = '#EF7689';
export const COLOR_RED_20 = getRgbaColor(COLOR_RED_20_BASE, '0.8');
export const COLOR_RED_30_BASE = '#EC546C';
export const COLOR_RED_30 = getRgbaColor('#EC546C', '0.8');

export const COLOR_BLUE_0 = getRgbaColor('#cde3fd', '0.9');
export const COLOR_BLUE_5_BASE = '#9bc7fc';
export const COLOR_BLUE_5 = getRgbaColor('#9bc7fc', '0.9');
export const COLOR_BLUE_10_BASE = '#6aabfa';
export const COLOR_BLUE_10 = getRgbaColor(COLOR_BLUE_10_BASE, '0.9');
export const COLOR_BLUE_20_BASE = '#388ff9';
export const COLOR_BLUE_20 = getRgbaColor(COLOR_BLUE_20_BASE, '0.8');
export const COLOR_BLUE_30 = getRgbaColor('#0774f8', '0.8');

export const COLOR_VIOLET_0 = getRgbaColor('#dfddf4', '0.9');
export const COLOR_VIOLET_5_BASE = '#c0bce9';
export const COLOR_VIOLET_5 = getRgbaColor('#c0bce9', '0.9');
export const COLOR_VIOLET_10_BASE = '#a09bdf';
export const COLOR_VIOLET_10 = getRgbaColor('#a09bdf', '0.9');
export const COLOR_VIOLET_20 = getRgbaColor('#817ad4', '0.8');
export const COLOR_VIOLET_30 = getRgbaColor('#6259CA', '0.8');

export const COLOR_LILAC_0 = getRgbaColor('#eaddf5', '0.9');
export const COLOR_LILAC_5_BASE = '#d5bbeb';
export const COLOR_LILAC_5 = getRgbaColor('#d5bbeb', '0.9');
export const COLOR_LILAC_10_BASE = '#c19ae1';
export const COLOR_LILAC_10 = getRgbaColor('#c19ae1', '0.9');
export const COLOR_LILAC_20 = getRgbaColor('#ac78d7', '0.8');
export const COLOR_LILAC_30 = getRgbaColor('#9857cd', '0.8');

export const getColorByIdx = (index: number, base = false) => {
  const colors = [
    base ? COLOR_GREEN_0_BASE : COLOR_GREEN_0,
    base ? COLOR_ORANGE_5_BASE : COLOR_ORANGE_5,
    base ? COLOR_GREEN_5_BASE : COLOR_GREEN_5,
    base ? COLOR_GREEN_10_BASE : COLOR_GREEN_10,
    base ? COLOR_RED_20_BASE : COLOR_RED_20,
    base ? COLOR_RED_30_BASE : COLOR_RED_30,
    base ? COLOR_BLUE_5_BASE : COLOR_BLUE_5,
    base ? COLOR_BLUE_10_BASE : COLOR_BLUE_10,
    base ? COLOR_VIOLET_5_BASE : COLOR_VIOLET_5,
    base ? COLOR_VIOLET_10_BASE : COLOR_VIOLET_10,
    base ? COLOR_LILAC_5_BASE : COLOR_LILAC_5,
    base ? COLOR_LILAC_10_BASE : COLOR_LILAC_10,
  ];

  return colors[index] || COLOR_GREY;
}

// Return a workable RGB int array [r,g,b] from hex representation
export const processHEX = (val: string): number[] => {
  // does the hex contain extra char?
  const hex = val.length > 6 ? val.substr(1, val.length - 1) : val;

  const r = hex.length > 3 ? hex.substr(0, 2) : hex.substr(0, 1) + hex.substr(0, 1);
  const g = hex.length > 3 ? hex.substr(2, 2) : hex.substr(1, 1) + hex.substr(1, 1);
  const b = hex.length > 3 ? hex.substr(4, 2) : hex.substr(2, 1) + hex.substr(2, 1);

  return [
    parseInt(r, 16),
    parseInt(g, 16),
    parseInt(b, 16)
  ]
}

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
}

export const getRgbaColor = (color: string, percent: string) => `rgba(${processHEX(color).join(',')}, ${percent})`;
